<template>
  <div>
    <!-- <h2>Status</h2> -->
    <!-- <b-row v-if="reportData.statistics"> -->
    <b-row v-if="reportData.statistics && eventId">
      <b-col v-if="reportData.statistics.Hired">
        <statistic-card-vertical

          color="success"
          icon="CheckIcon"
          :statistic="reportData.statistics.Hired"
          statistic-title="Hired"
        />
      </b-col>

      <b-col v-if="reportData.statistics.Shortlisted">
        <statistic-card-vertical
          color="warning"
          icon="ListIcon"
          :statistic="reportData.statistics.Shortlisted"
          statistic-title="Shortlisted "
        />
      </b-col>

      <b-col v-if="reportData.statistics.Rejected">
        <statistic-card-vertical
          color="danger"
          icon="XIcon"
          :statistic="reportData.statistics.Rejected"
          statistic-title="Unsuccessful"
        />
      </b-col>

      <b-col v-if="reportData.statistics['Kept In View']">
        <statistic-card-vertical
          color="warning"
          icon="ClockIcon"
          :statistic="reportData.statistics['Kept In View']"
          statistic-title="Pending"
        />
      </b-col>

      <b-col v-if="reportData.statistics.Unknown">
        <statistic-card-vertical

          color="danger"
          icon="AlertTriangleIcon"
          :statistic="reportData.statistics.Unknown"
          statistic-title="Unknown"
        />
      </b-col>
    </b-row>
    <hr>

    <b-row
      v-if="reportData.statistics"
      class="mt-1"
    >
      <!-- Page Visits -->
      <b-col
        cols="12"
        xl="4"
        md="4"
        sm="6"
      >

        <statistic-card-horizontal
          icon="DribbbleIcon"
          :statistic="reportData.statistics.avg_bounce_rate"
          statistic-title="Average Bounce Rate"
        />
        <event-page-chart
          :report-data="reportData"
          height="250"
          section="job_seeker"
        />
      </b-col>

      <b-col
        cols="12"
        xl="8"
        md="8"
        sm="6"
      >
        <statistic-card-horizontal
          v-if="reportData.statistics.tracking"
          icon="ClockIcon"
          :statistic="reportData.statistics.tracking.session_duration_average.session_duration_average"
          statistic-title="Average Session Duration"
        />

        <event-actions-chart
          :report-data="reportData"
          :which-page="'summary'"
          title="Event Page Views Statistics - 30 days"
          section="job_seeker"
          height="320"
          data-name="Event Page Views"
          data-key="event_job_visit"
        />

      </b-col>

      <b-col
        cols="12"
        xl="12"
        md="12"
        sm="12"
      >

        <event-actions-chart
          :report-data="reportData"
          :which-page="'summary'"
          height="350"
          title="Job Views Statistics - 30 days"
          section="job_seeker"
          data-name="Job Views"
          data-key="job_visit"
        />

      </b-col>

      <b-col
        cols="12"
        xl="12"
        md="12"
        sm="12"
      >

        <event-actions-chart
          :report-data="reportData"
          :which-page="'summary'"
          height="350"
          title="Interested Applicants - 30 days"
          section="job_seeker"
          data-name="Interested Applicants"
          data-key="apply_now_clicks"
        />

      </b-col>

      <b-col
        v-if="!eventId"
        cols="12"
        xl="6"
        md="6"
      >
        <custom-analytic-card
          title="Page Visits"
        >
          <table class="custom-table">
            <thead>
              <tr>
                <td>Page</td>
                <td>Unique</td>
                <td>Absolute</td>
              </tr>
            </thead>
            <tbody v-if="reportData.statistics.tracking">
              <tr
                v-for="pageVisit, key in reportData.statistics.tracking.page_visit_unique_and_absolute"
                :key="key.key"
              >
                <td>{{ key }}</td>
                <td class="font-weight-bolder text-left">
                  {{ $thousandSeparated(pageVisit.unique) }}
                </td>
                <td class="font-weight-bolder text-left">
                  {{ $thousandSeparated(pageVisit.absolute) }}
                </td>
              </tr>

              <tr class="row-summary">
                <td><b>TOTAL</b></td>
                <td class="text-left">
                  {{
                    $thousandSeparated(
                      Object.values(reportData.statistics.tracking.page_visit_unique_and_absolute).map(item => item.unique).reduce((sum, count) => sum + count, 0)
                    )
                  }}
                </td>
                <td class="text-left">
                  {{
                    $thousandSeparated(
                      Object.values(reportData.statistics.tracking.page_visit_unique_and_absolute).map(item => item.absolute).reduce((sum, count) => sum + count, 0)
                    )
                  }}
                </td>
              </tr>
            </tbody>

          </table>
        </custom-analytic-card>
      </b-col>

      <!-- TRACKING ANALYTICS -->
      <b-col
        v-if="!eventId"
        cols="12"
        xl="6"
        md="6"
      >
        <custom-analytic-card
          title="Tracking Analytics"
        >
          <table class="custom-table">
            <tbody v-if="reportData.statistics.tracking">
              <tr>
                <td>Average Session Duration</td>
                <td class="font-weight-bolder text-right">
                  {{ reportData.statistics.tracking.session_duration_average }}
                </td>
              </tr>
              <tr>
                <td>Total 'Apply Now' button clicked</td>
                <td class="font-weight-bolder text-right">
                  {{ $thousandSeparated(reportData.statistics.tracking.form_abandon_rate.total_form_count) }}
                </td>
              </tr>
              <tr>
                <td>Submitted Application Form</td>
                <td
                  class="font-weight-bolder"
                >
                  <div class="d-flex justify-content-between">
                    <div>
                      ( {{ parseFloat(reportData.statistics.tracking.form_abandon_rate.form_submit_percentage).toFixed(2) }}% )
                    </div>
                    <div>
                      {{ $thousandSeparated(reportData.statistics.tracking.form_abandon_rate.form_submit_count) }}
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>Abandoned Application Form</td>
                <td
                  class="font-weight-bolder"
                >
                  <div class="d-flex justify-content-between">
                    <div>
                      ( {{ parseFloat(reportData.statistics.tracking.form_abandon_rate.form_not_submit_percentage).toFixed(2) }}% )
                    </div>
                    <div>
                      {{ $thousandSeparated(reportData.statistics.tracking.form_abandon_rate.form_not_submit_count) }}
                    </div>
                  </div>
                </td>
              </tr>

              <!-- TOURISM PAGE VISIT -->
              <tr>
                <td>Event Page Visit</td>
                <td
                  class="font-weight-bolder"
                >
                  <div class="d-flex justify-content-end">
                    <div>
                      {{ reportData.statistics.tracking.tourism_event.absolute_tourism_event_page_visit }}
                    </div>
                  </div>
                </td>
              </tr>

              <!-- TOURISM VIEW MORE CLICK -->
              <tr>
                <td>Event Jobs Click</td>
                <td
                  class="font-weight-bolder"
                >
                  <div class="d-flex justify-content-end">
                    <div>
                      {{ reportData.statistics.tracking.tourism_event.view_more_click }}
                    </div>
                  </div>
                </td>
              </tr>

              <!-- TOURISM APPLY NOW CLICK -->
              <tr>
                <td>Tourism Event Apply Now Click</td>
                <td
                  class="font-weight-bolder"
                >
                  <div class="d-flex justify-content-end">
                    <div>
                      {{ reportData.statistics.tracking.tourism_event.apply_now_click }}
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </custom-analytic-card>
      </b-col>

      <!-- Source of Visitor (UTM) -->
      <b-col
        cols="12"
        xl="12"
        md="12"
      >
        <custom-analytic-card
          title="Source of Visitors (UTM)"
        >
          <table class="custom-table">
            <thead>
              <tr>
                <td>Source</td>
                <td>Medium</td>
                <td>Campaign</td>
                <td>Count</td>
              </tr>
            </thead>

            <tbody v-if="reportData.statistics.tracking">
              <tr
                v-for="utm, key, index in reportData.statistics.tracking.source_of_visitor_utm"
                :key="utm.key"
              >
                <td>{{ utm.utm_source }}</td>
                <td>{{ utm.utm_medium }}</td>
                <td>{{ utm.utm_campaign }}</td>
                <td class="font-weight-bolder">
                  {{ utm.count }}
                </td>
              </tr>
            </tbody>
          </table>
        </custom-analytic-card>
      </b-col>
    </b-row>

    <h3 v-if="!eventId">
      Job Seeker
    </h3>

    <b-row v-if="reportData.statistics && !eventId">
      <b-col v-if="reportData.statistics.total_jobs">
        <statistic-card-horizontal

          icon="BriefcaseIcon"
          :statistic="reportData.statistics.total_jobs"
          statistic-title="Total Jobs"
        />
      </b-col>
      <b-col v-if="reportData.statistics.total_applicants">
        <statistic-card-horizontal

          icon="UserIcon"
          :statistic="reportData.statistics.total_applicants"
          statistic-title="Total Applicants"
        />
      </b-col>
      <b-col v-if="reportData.statistics.total_applications">
        <statistic-card-horizontal

          icon="CalendarIcon"
          :statistic="reportData.statistics.total_applications"
          statistic-title="Scheduled Interview"
        />
      </b-col>
      <b-col v-if="reportData.statistics.all_interviews_completed">
        <statistic-card-horizontal

          variant="success"
          icon="CheckIcon"
          :statistic="reportData.statistics.all_interviews_completed"
          statistic-title="Interview Completed"
        />
      </b-col>
    </b-row>

    <b-row v-if="!eventId">
      <!-- <b-col
        cols="12" xl="6" md="12"
      >
        <number-of-applicant-chart
          :report-data="reportData"
          :which-page="'summary'"
        />
      </b-col> -->

      <!-- <b-col
        cols="12" xl="6" md="6"
      >
        <popular-company :data="reportData.popular_companies" />
      </b-col> -->

      <b-col
        cols="12"
        xl="3"
        md="6"
      >
        <gender-chart
          :component-options="{
            cardHeight: '60vh'
          }"
          :report-data="reportData"
          section="job_seeker"
        />
      </b-col>

      <b-col
        cols="12"
        xl="3"
        md="6"
      >
        <employment-status-chart
          :component-options="{
            cardHeight: '60vh'
          }"
          :report-data="reportData"
          section="job_seeker"
        />
      </b-col>

      <b-col
        cols="12"
        xl="3"
        md="6"
      >
        <citizenship-chart
          :component-options="{
            cardHeight: '60vh'
          }"
          :report-data="reportData"
          section="job_seeker"
        />
      </b-col>

      <b-col
        cols="12"
        xl="3"
        md="6"
      >
        <qualification-level-list
          :component-options="{
            cardHeight: '60vh'
          }"
          :report-data="reportData"
          section="job_seeker"
        />
      </b-col>

      <b-col
        cols="12"
        xl="6"
        md="6"
      >
        <statistic-card-horizontal
          icon="UserIcon"
          :statistic="reportData.statistics.number_of_users_account"
          statistic-title="Number of Job Seekers Account"
        />
        <b-col
          class="m-0 p-0"
          cols="12"
          xl="12"
          md="12"
        >
          <number-of-applicant-chart
            :component-options="{
              cardHeight: '41vh'
            }"
            :report-data="reportData"
            :which-page="'summary'"
            title="User Login Activity (Based on login time) - 30 days"
            section="job_seeker"
          />
        </b-col>
      </b-col>

      <b-col
        cols="12"
        xl="6"
        md="6"
      >
        <b-col
          class="m-0 p-0"
          cols="12"
          xl="12"
          md="12"
        >
          <ChartComponent
            :component-options="{
              cardHeight: '60vh'
            }"
            :report-data="reportData"
            :which-page="'summary'"
            title="Job Applications - 30 days"
            section="job_seeker"
            data-name="job_applications_chart"
          />
        </b-col>
      </b-col>

      <!-- <b-col
        cols="12" xl="3" md="6"
      >
        <interview-type-chart :report-data="reportData" />
      </b-col> -->

    </b-row>

    <h3 v-if="!eventId">
      Employer
    </h3>

    <b-row v-if="!eventId">
      <!-- <b-col
        cols="12" xl="3" md="6"
      >
        <gender-chart :report-data="reportData" section="employer"/>
      </b-col>
      <b-col
        cols="12" xl="3" md="6"
      >
        <employment-status-chart :report-data="reportData" section="employer"/>
      </b-col> -->

      <b-col
        cols="12"
        xl="6"
        md="6"
      >
        <statistic-card-horizontal
          icon="UserIcon"
          :statistic="reportData.statistics.number_of_employers_account"
          statistic-title="Number of Employers Account"
        />
        <b-col
          class="m-0 p-0"
          cols="12"
          xl="12"
          md="12"
        >
          <number-of-applicant-chart
            :component-options="{
              cardHeight: '41vh'
            }"
            :report-data="reportData"
            :which-page="'summary'"
            title="User Login Activity (Based on login time) - 30 days"
            section="employer"
          />
        </b-col>
      </b-col>

      <b-col
        cols="12"
        xl="6"
        md="6"
      >
        <b-col
          class="m-0 p-0"
          cols="12"
          xl="12"
          md="12"
        >
          <ChartComponent
            :component-options="{
              cardHeight: '60vh'
            }"
            :report-data="reportData"
            :which-page="'summary'"
            title="Active Job - 30 days"
            section="employer"
            data-name="active_job_chart"
          />
        </b-col>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue'
import StatisticCardVertical from '@core/components/statistics-cards/StatisticCardVertical.vue'

import CitizenshipChart from './CitizenshipChart.vue'
import EmploymentStatusChart from './EmploymentStatusChart.vue'
import GenderChart from './GenderChart.vue'
import EventPageChart from './EventPageChart.vue'
import EventActionsChart from './EventActionsChart.vue'
import NumberOfApplicantChart from './NumberOfApplicantChart.vue'
import PopularCompany from './PopularCompany.vue'
import QualificationLevelList from './QualificationLevelList.vue'

import CustomAnalyticCard from './CustomAnalyticCard.vue'

import ChartComponent from './ChartComponent.vue'

export default {
  components: {
    StatisticCardHorizontal,
    StatisticCardVertical,

    CitizenshipChart,
    EmploymentStatusChart,
    GenderChart,
    EventPageChart,
    EventActionsChart,
    NumberOfApplicantChart,
    PopularCompany,
    QualificationLevelList,

    CustomAnalyticCard,

    ChartComponent,
  },
  props: ['reportData'],
  data() {
    return {
      hide_temporary: false,
      eventId: null,
    }
  },
  created() {
    const eventId = this.$route.params.report_name.split('__')[1]
    this.eventId = eventId
  },
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables/variables.scss";

.custom-table {
  width: 100%;

  thead {
    line-height: 3.5rem;
    background: $primary;
    color: $white;
  }
  tbody {
    line-height: 3rem;
  }

  tbody tr:nth-child(even) {
    background: lighten($primary, 48%);
  }

  thead td {
    padding-inline: 1rem;
  }

  tbody td {
    padding-inline: 1rem;
  }
  .row-summary {
    background: lighten($primary, 40%);
    font-weight: bolder;
    font-size: 1.5rem;
    line-height: 3.5rem;
  }
}
</style>
